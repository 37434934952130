import React from "react"
import ReactGA from 'react-ga4'
import "./Home.css"
import Navbar from "./Navbar" // Import the Navbar component

function Home() {

     // Function to handle the button click
     const handleButtonClick = () => {
        // Send event to Google Analytics
        ReactGA.event({
            category: 'User',
            action: 'Clicked Try It Out Button',
            label: 'Home Page'
        });

        // Open the link after sending the event to Google Analytics
        window.open("https://apps.apple.com/us/app/fical-money-planner-calendar/id6451495828", "_blank");
    };

    return (
        <div className='container'>
            <Navbar/>
            <div className='content-wrapper'>
                <div className='left-panel'>
                    <img
                        src={process.env.PUBLIC_URL + "/render-1.png"}
                        alt='Render'
                        className='desktop-image'
                    />
                </div>
                <div className='right-panel'>
                    <div className='fical-branding'>
                        <h1>
                            Simple money <br />
                            planning
                        </h1>
                        <p></p>
                        <h3>
                            The simplest way for you to plan,
                            <br /> budget, and track your money
                        </h3>
                        <button onClick={handleButtonClick}>
                            Try It Out
                        </button>
                    </div>
                    <div className='left-panel'>
                        <img
                            src={process.env.PUBLIC_URL + "/render-1.png"}
                            alt='Render'
                            className="mobile-image"
                            />
                    </div>
                </div>
            </div>
            {/* <div className='footer'>
                        <div className='footer-wrapper'>
                        <p>About</p><p>Privacy</p>
                        </div>
                    </div> */}
        </div>
    )
}

export default Home
