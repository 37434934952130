import React from "react"
import {Link} from "react-router-dom"
import "./Navbar.css" // Make sure you have the correct path to your CSS file
function Navbar() {
    return (
        <nav className='navbar'>
            <div className='logo-container'>
                {/* <img
                        src={process.env.PUBLIC_URL + "/favicon2.ico"}
                        alt='Render'
                    /> */}
                <Link className='logo' to='/'>
                    Fical
                </Link>
            </div>
            <div className="right">
                <Link className='nav-links' to='/contact'>Contact</Link>
                <Link className='nav-links' to='/privacy'>Privacy</Link>
            </div>
            

            {/* Add other links as needed */}
        </nav>
    )
}

export default Navbar
