import React from 'react';
import Navbar from './Navbar';
import './Contact.css';

export default function Contact(props) {
    return (
        <div className="support-page">
            <Navbar />
            <div className="support-content">
                <h1>Need Help?</h1>
                <p>If you're experiencing issues or have questions about our app, we're here to help.</p>
                
                <h2>Contact Us</h2>
                <p>If you didn't find what you were looking for in our FAQ, feel free to reach out via email:</p>
                <ul>
                    <li>Email: <a href="mailto:pikpaldev@gmail.com">pikpaldev@gmail.com</a></li>
                    {/* Optionally add more contact methods here */}
                </ul>
                
                {/* Include a contact form for direct messaging from the page */}
                {/* <ContactForm /> */}
            </div>
            {/* Include Footer with additional links or legal information */}
            {/* <Footer /> */}
        </div>
    );
}
