import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import './App.css';
import './global.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy';
import Home from './components/Home';
import Contact from './components/Contact';

// Create a separate component for the routes and tracking
const TrackRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-DZWVLNRVDF');
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <TrackRoutes />
      </div>
    </Router>
  );
}

export default App;
